@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 768px) {
  /* Mobile styles */
  .container {
    padding: 1rem;
  }

  table {
    width: 100%;
    font-size: 0.875rem; /* Smaller font for mobile */
  }

  th, td {
    padding: 0.5rem;
    overflow-wrap: break-word; /* Wrap text in cells */
  }

  .time {
    font-weight: bold;
  }
  .text-xs {
      font-size: 0.5rem !important;
      line-height: .5rem !important;
  }
}
